import {
  EuiGlobalToastList,
  EuiLoadingSpinner,
  EuiOverlayMask,
} from "@elastic/eui";
import "@elastic/eui/dist/eui_theme_light.css";
import React, { lazy, Suspense, useEffect, useState } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import "./App.scss";
import EventBus from "./common/eventBus";
import { ProtectedRoute } from "./common/protectedRoute";
import LayOut from "./components/LayOut";
// import CashoutDetails from "./pages/cashouts/cashoutDetails";
// import ExportedCashouts from "./pages/cashouts/exportedCashouts";
// import CashOuts from "./pages/cashouts/list";
// import ApplicationDetailStats from "./pages/finance/applicationDetails";
// import ApplicationStats from "./pages/finance/applications";
// import ExportedTransactions from "./pages/finance/exportedTransactions";
// import Transactions from "./pages/finance/transactions";
// import TransactionDetails from "./pages/finance/transactions/details/TransactionDetails";
// import KycDetails from "./pages/kycs/kycDetails";
// import KycList from "./pages/kycs/list";
// import Login from "./pages/login";
// import ApplicationDetails from "./pages/merchants/applicationDetails";
// import Applications from "./pages/merchants/applications";
// import Merchants from "./pages/merchants/list";
// import MerchantDetails from "./pages/merchants/merchantDetails";
// import OverviewMerchants from "./pages/merchants/overviewMerchants";
// import Payouts from "./pages/merchants/payouts";
// import PayoutInfo from "./pages/merchants/payouts/PayoutInfo";
// import AppProfileDetails from "./pages/serviceAccount/appProfile/details";
// import ListAppProfile from "./pages/serviceAccount/appProfile/list";
// import ListServiceAcc from "./pages/serviceAccount/listServiceAcc";
// import ServiceAccDetails from "./pages/serviceAccount/serviceAccDetails";
// import UserInformation from "./pages/setting/profile";
// import ListUsers from "./pages/setting/users/listUsers";
// import Providers from "./pages/serviceAccount/providers";
// import Countries from "./pages/serviceAccount/countries";
// import ManageAppKyc from "./pages/kycs/ManageAppKyc";
// import Constraints from "./pages/serviceAccount/constraints";
// import ConstraintForm from "./pages/serviceAccount/constraints/Components/ConstraintForm";
import { EditModal } from "./pages/serviceAccount/constraints/Components/ConstraintEdit";
import InvoiceList from "./pages/invoice/List/InvoiceList";
import Invoices from "./pages/invoice";
import InvoiceDetails from "./pages/invoice/InvoiceDetails";
import MerchantInvoiceList from "./pages/invoice/List/merchantInvoiceList";
// import AppConstraints from "./pages/serviceAccount/AppConstraints";
// import AppConstForm from "./pages/serviceAccount/AppConstraints/Components/AppConstForm";
// import Authorizer from "./pages/serviceAccount/Authorizer";
// import Partners from "./pages/partnerPortal/list";
// import Apis from "./pages/partnerPortal/apis";
// import Configuarations from "./pages/partnerPortal/configurations";
// import KycsPpp from "./pages/partnerPortal/kycsPpp";
// import BillingPpp from "./pages/partnerPortal/billing";
// import TransactionPpp from "./pages/partnerPortal/Transaction";
// import PppLogs from "./pages/partnerPortal/pppLogs";
// import Deposits from "./pages/deposits/list";
// import ExportedLists from "./pages/deposits/exportedLists";
// import ExchangeRate from "./pages/partnerPortal/exchangeRate";
// import CashManagement from "./pages/cashManagement";
// import PosList from "./pages/cashManagement/components/PosList";
// import PosTransactions from "./pages/cashManagement/transactions";
// import PosDetails from "./pages/cashManagement/components/PosDetails";
// import PosAgentStatistics from "./pages/cashManagement/components/PosAgentStatistics";
// import AgentDetails from "./pages/cashManagement/components/AgentDetails";
// import Products from "./pages/Products";

// import ProductList from "./pages/Products/components/ProductList";
const CashoutDetails = lazy(() => import("./pages/cashouts/cashoutDetails"));
const ExportedCashouts = lazy(() =>
  import("./pages/cashouts/exportedCashouts")
);
const CashOuts = lazy(() => import("./pages/cashouts/list"));
const ApplicationDetailStats = lazy(() =>
  import("./pages/finance/applicationDetails")
);
const ApplicationStats = lazy(() => import("./pages/finance/applications"));
const ExportedTransactions = lazy(() =>
  import("./pages/finance/exportedTransactions")
);
const Transactions = lazy(() => import("./pages/finance/transactions"));
const TransactionDetails = lazy(() =>
  import("./pages/finance/transactions/details/TransactionDetails")
);
const KycDetails = lazy(() => import("./pages/kycs/kycDetails"));
const Login = lazy(() => import("./pages/login"));
const KycList = lazy(() => import("./pages/kycs/list"));
const ApplicationDetails = lazy(() =>
  import("./pages/merchants/applicationDetails")
);
const Applications = lazy(() => import("./pages/merchants/applications"));
const Merchants = lazy(() => import("./pages/merchants/list"));
const MerchantDetails = lazy(() => import("./pages/merchants/merchantDetails"));
const OverviewMerchants = lazy(() =>
  import("./pages/merchants/overviewMerchants")
);
const Payouts = lazy(() => import("./pages/merchants/payouts"));
const PayoutInfo = lazy(() => import("./pages/merchants/payouts/PayoutInfo"));
const AppProfileDetails = lazy(() =>
  import("./pages/serviceAccount/appProfile/details")
);
const ListAppProfile = lazy(() =>
  import("./pages/serviceAccount/appProfile/list")
);
const ServiceAccDetails = lazy(() =>
  import("./pages/serviceAccount/serviceAccDetails")
);
const ListServiceAcc = lazy(() =>
  import("./pages/serviceAccount/listServiceAcc")
);
const UserInformation = lazy(() => import("./pages/setting/profile"));
const ListUsers = lazy(() => import("./pages/setting/users/listUsers"));
const Countries = lazy(() => import("./pages/serviceAccount/countries"));
const Providers = lazy(() => import("./pages/serviceAccount/providers"));
const ManageAppKyc = lazy(() => import("./pages/kycs/ManageAppKyc"));
const Constraints = lazy(() => import("./pages/serviceAccount/constraints"));
const ProductList = lazy(() =>
  import("./pages/Products/components/ProductList")
);
const ConstraintForm = lazy(() =>
  import("./pages/serviceAccount/constraints/Components/ConstraintForm")
);
const Products = lazy(() => import("./pages/Products"));
const AgentDetails = lazy(() =>
  import("./pages/cashManagement/components/AgentDetails")
);
const PosAgentStatistics = lazy(() =>
  import("./pages/cashManagement/components/PosAgentStatistics")
);
const PosDetails = lazy(() =>
  import("./pages/cashManagement/components/PosDetails")
);
const PosTransactions = lazy(() =>
  import("./pages/cashManagement/transactions")
);
const PosList = lazy(() => import("./pages/cashManagement/components/PosList"));
const CashManagement = lazy(() => import("./pages/cashManagement"));
const ExchangeRate = lazy(() => import("./pages/partnerPortal/exchangeRate"));
const ExportedLists = lazy(() => import("./pages/deposits/exportedLists"));
const Deposits = lazy(() => import("./pages/deposits/list"));
const PppLogs = lazy(() => import("./pages/partnerPortal/pppLogs"));
const TransactionPpp = lazy(() => import("./pages/partnerPortal/Transaction"));
const BillingPpp = lazy(() => import("./pages/partnerPortal/billing"));
const KycsPpp = lazy(() => import("./pages/partnerPortal/kycsPpp"));
const Configuarations = lazy(() =>
  import("./pages/partnerPortal/configurations")
);
const Apis = lazy(() => import("./pages/partnerPortal/apis"));
const Partners = lazy(() => import("./pages/partnerPortal/list"));
const Authorizer = lazy(() => import("./pages/serviceAccount/Authorizer"));
const AppConstForm = lazy(() =>
  import("./pages/serviceAccount/AppConstraints/Components/AppConstForm")
);
const AppConstraints = lazy(() =>
  import("./pages/serviceAccount/AppConstraints")
);

const DashboardPage = lazy(() => import("./pages/dashboard/index"));
const SettingPage = lazy(() => import("./pages/setting/index"));
const KycsPage = lazy(() => import("./pages/kycs/index"));
const MerchantsPage = lazy(() => import("./pages/merchants/index"));
const PartnersPage = lazy(() => import("./pages/partnerPortal/index"));
const NotFoundPage = lazy(() => import("./pages/notFound/index"));
const FinancePage = lazy(() => import("./pages/finance/index"));
const CashoutsPage = lazy(() => import("./pages/cashouts/index"));
const DepositsPage = lazy(() => import("./pages/deposits/index"));
const ServiceAccountPage = lazy(() => import("./pages/serviceAccount/index"));

function App() {
  const [toasts, setToasts] = useState([]);
  const [maskOpen, changeMask] = useState(false);

  const removeToast = (removedToast) => {
    setToasts(toasts.filter((toast) => toast.id !== removedToast.id));
  };

  useEffect(() => {
    // event log out

    EventBus.on("logout", () => {
      console.log("App disconnect");
    });

    // event toast message

    EventBus.on("toast", (message) => {
      setToasts(toasts.concat(message));
    });

    // event loading

    EventBus.on("loading", (data) => {
      changeMask(data);
    });

    return () => {
      // remove event
      EventBus.remove("logout");
      EventBus.remove("toast");
    };
  }, []);

  const modal = (
    <>
      <EuiOverlayMask>
        <EuiLoadingSpinner size="xxl" />
      </EuiOverlayMask>
    </>
  );

  return (
    <>
      {maskOpen ? modal : undefined}
      <EuiGlobalToastList
        toasts={toasts}
        dismissToast={removeToast}
        toastLifeTimeMs={6000}
      />
      <Suspense
        fallback={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <EuiLoadingSpinner size="xxl" />
          </div>
        }
      >
        <Routes>
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <LayOut />
              </ProtectedRoute>
            }
          >
            <Route index element={<DashboardPage />} />
            <Route path="/kycs" element={<KycsPage />}>
              <Route index path="list" element={<KycList />} />
              <Route index path="manage" element={<ManageAppKyc />} />
              <Route
                path="detail/:kycId/:userId/:level"
                element={<KycDetails />}
              />
              <Route path="overview" element={<>Overview</>} />
              <Route path="create" element={<>Create</>} />
            </Route>

            <Route path="/merchants" element={<MerchantsPage />}>
              <Route path="overview" element={<OverviewMerchants />} />
              <Route path="list" element={<Merchants />} />
              <Route path="payouts" element={<Payouts />} />
              <Route path="payoutDetails/:payout_id" element={<PayoutInfo />} />
              <Route path="applications" element={<Applications />} />
              <Route path="transactions" element={<Transactions />} />
              {/* <Route path="cashmanagement" element={<CashManagement />} /> */}
              <Route
                path="applicationDetails/:app_id/:merchant_id"
                element={<ApplicationDetails />}
              />
              <Route
                path="merchantDetails/:merchant_id"
                element={<MerchantDetails />}
              />
            </Route>
            <Route path="/products" element={<Products />}>
              <Route path="list" element={<ProductList />} />
            </Route>
            <Route path="/pos" element={<CashManagement />}>
              <Route path="list" element={<PosList />} />
              <Route path="list/:id" element={<PosDetails />} />
              <Route
                path="list/:id/stats/:agent_id"
                element={<AgentDetails />}
              />
              <Route path="transaction" element={<PosTransactions />} />
            </Route>

            <Route path="/partner-portal" element={<PartnersPage />}>
              <Route path="partners" index element={<Partners />} />
              <Route path="kycs/*" element={<KycsPpp />} />
              <Route path="apis" element={<Apis />} />
              <Route path="billing" element={<BillingPpp />} />
              <Route path="transaction" element={<TransactionPpp />} />
              <Route path="logs" element={<PppLogs />} />
              <Route path="configurations" element={<Configuarations />} />
              <Route path="exchangeRate" element={<ExchangeRate />} />
            </Route>

            <Route path="/invoice" element={<Invoices />}>
              <Route path="list" element={<InvoiceList />} />
              <Route path="list/:invoiceId/:id" element={<InvoiceDetails />} />
              <Route path="list/:invoiceId" element={<MerchantInvoiceList />} />
            </Route>

            <Route path="/finance" element={<FinancePage />}>
              <Route path="transactions" element={<Transactions />} />
              <Route path="applications" element={<ApplicationStats />} />
              <Route
                path="transactionDetails/:transaction_id"
                element={<TransactionDetails />}
              />
              <Route
                path="applicationDetails/:app_id/:merchant_id"
                element={<ApplicationDetailStats />}
              />
              <Route index path="exports" element={<ExportedTransactions />} />
            </Route>
            <Route path="/finance" element={<FinancePage />}>
              <Route path="transactions" element={<Transactions />} />
              <Route path="applications" element={<ApplicationStats />} />
              <Route
                path="transactionDetails/:transaction_id"
                element={<TransactionDetails />}
              />
              <Route
                path="applicationDetails/:app_id/:merchant_id"
                element={<ApplicationDetailStats />}
              />
              <Route index path="exports" element={<ExportedTransactions />} />
            </Route>

            <Route path="/serviceAccount" element={<ServiceAccountPage />}>
              <Route path="list" element={<ListServiceAcc />} />
              <Route path="providers" element={<Providers />} />
              <Route path="constraints/*" element={<ConstrainChild />} />
              <Route path="Appconstraints/*" element={<AppConstrainChild />} />
              <Route path="authorizer" element={<Authorizer />} />
              <Route path="countries" element={<Countries />} />
              <Route
                path="details/:account_id"
                element={<ServiceAccDetails />}
              />
              <Route path="appProfile" element={<Outlet />}>
                <Route index path="list" element={<ListAppProfile />} />
                <Route
                  path="details/:profile_id"
                  element={<AppProfileDetails />}
                ></Route>
              </Route>
            </Route>
            <Route path="/serviceAccount" element={<ServiceAccountPage />}>
              <Route path="list" element={<ListServiceAcc />} />
              <Route path="providers" element={<Providers />} />
              <Route path="constraints/*" element={<ConstrainChild />} />
              <Route path="Appconstraints/*" element={<AppConstrainChild />} />
              <Route path="authorizer" element={<Authorizer />} />
              <Route path="countries" element={<Countries />} />
              <Route
                path="details/:account_id"
                element={<ServiceAccDetails />}
              />
              <Route path="appProfile" element={<Outlet />}>
                <Route index path="list" element={<ListAppProfile />} />
                <Route
                  path="details/:profile_id"
                  element={<AppProfileDetails />}
                ></Route>
              </Route>
            </Route>

            <Route path="/cashouts" element={<CashoutsPage />}>
              <Route index path="list" element={<CashOuts />} />
              <Route
                path="details/:cashout_id/:app_id"
                element={<CashoutDetails />}
              />
              <Route index path="exports" element={<ExportedCashouts />} />
            </Route>
            <Route path="/cashouts" element={<CashoutsPage />}>
              <Route index path="list" element={<CashOuts />} />
              <Route
                path="details/:cashout_id/:app_id"
                element={<CashoutDetails />}
              />
              <Route index path="exports" element={<ExportedCashouts />} />
            </Route>

            <Route path="/deposits" element={<DepositsPage />}>
              <Route index path="list" element={<Deposits />} />
              <Route index path="exports" element={<ExportedLists />} />
            </Route>
            <Route path="/deposits" element={<DepositsPage />}>
              <Route index path="list" element={<Deposits />} />
              <Route index path="exports" element={<ExportedLists />} />
            </Route>

            <Route path="/setting" element={<SettingPage />}>
              <Route path="users" element={<ListUsers />} />
              <Route path="profile" element={<UserInformation />} />
            </Route>
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Suspense>
    </>
  );
}

const ConstrainChild = () => {
  return (
    <Routes>
      <Route path="/" element={<Constraints />} />
      <Route path="/form" element={<ConstraintForm />} />
      <Route path="/edit-constraint" element={<EditModal />} />
    </Routes>
  );
};
const AppConstrainChild = () => {
  return (
    <Routes>
      <Route path="/" element={<AppConstraints />} />
      <Route path="/form" element={<AppConstForm />} />
      <Route path="/edit-constraint" element={<EditModal />} />
    </Routes>
  );
};
export default App;
