export const fakeBillingData = () => {
  return [
    {
      amount: 200,
      lastChangedDateTime: "2015-10-10",
      created_at: "2015-10-10",
      id: 12,
      status: "PAID",
    },
    {
      amount: 150,
      lastChangedDateTime: "2018-05-22",
      created_at: "2018-05-22",
      id: 18,
      status: "PAID",
    },
    {
      amount: 300,
      lastChangedDateTime: "2019-08-15",
      created_at: "2019-08-15",
      id: 25,
      status: "UNPAID",
    },
    {
      amount: 500,
      lastChangedDateTime: "2020-03-07",
      created_at: "2020-03-07",
      id: 31,
      status: "PAID",
    },
    {
      amount: 100,
      lastChangedDateTime: "2021-11-30",
      created_at: "2021-11-30",
      id: 40,
      status: "PENDING",
    },
  ];
};

export function getCurrentYear() {
  const currentDate = new Date();
  return currentDate.getFullYear();
}

export function generateYearArray(startYear, endYear) {
  const years = [];

  for (let year = endYear; year >= startYear; year--) {
    years.push(year);
  }

  return years;
}

export const Divider = (props) => (
  <div
    style={{
      width: "100%",
      height: "1px",
      backgroundColor: "rgb(192, 192, 192)",
    }}
    {...props}
  />
);
