import {
  EuiButton,
  EuiButtonEmpty,
  EuiEmptyPrompt,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiInMemoryTable,
  EuiPanel,
  EuiSpacer,
  EuiSuperSelect,
  EuiTablePagination,
  EuiText,
  EuiTitle,
} from "@elastic/eui";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { invoiceColumn } from "./utils/columns";
import { message } from "./utils";
import { getAllInvoiceApi } from "../../../services/invoice-service";
import { isAxiosError } from "axios";
import useInvoiceListFilters from "./hooks/useInvoiceListFilters";
import InvoiceFilters from "./components/InvoiceFilters";
import { index } from "mathjs";

const InvoiceList = () => {
  const [loading, setLoading] = useState(false);
  const [invoiceData, setInvoiceData] = useState([]);
  const invoiceTableRef = useRef();

  const [pageCount, setPageCount] = useState(0);

  const [activePage, setActivePage] = useState(0);
  const goToPage = (pageNumber) => setActivePage(pageNumber);
  const [rowSize, setRowSize] = useState(10);
  const [totalEntries, setTotalEntries] = useState(0);

  const changeItemsPerPage = (pageSize) => {
    setPageCount(Math.ceil(totalEntries / pageSize));
    setRowSize(pageSize);
    setActivePage(0);
    localStorage.removeItem("merchantPaginationToken");
  };

  const {
    application_id,
    invoice_id,
    payment_id,
    status,
    resetFilter,
    statusOptions,
    handleStatus,
    handlers,
  } = useInvoiceListFilters();

  const query = {
    page: activePage,
    limit: rowSize,
    status: status === "ALL" ? null : status,
    application_id,
    payment_id,
    invoice_id,
  };

  const getInvoiceData = async () => {
    setLoading(true);

    try {
      const data = await getAllInvoiceApi(query);
      console.log({ ColectedDtata: data });
      setInvoiceData(data);
      setTotalEntries(data?.meta?.totalItems);
      setPageCount(data?.meta?.totalPages);
    } catch (error) {
      if (isAxiosError(error)) {
        console.log(error.response?.data?.message);
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    getInvoiceData();
  }, [activePage, rowSize, status, application_id, payment_id, invoice_id]);

  const [filterDrawer, setFilterDrawer] = useState(false);

  const toggleFilterDrawer = () => setFilterDrawer((prev) => !prev);

  // const yearGenerator = ({ startYear = 2005, endYear }) => {
  //   let yearsArray = [];
  //   for (let i = startYear; i <= endYear; i++) {
  //     yearsArray.push(i);
  //   }
  //   return yearsArray;
  // };

  // console.log({
  //   allYears: yearGenerator({
  //     startYear: 2020,
  //     endYear: new Date().getFullYear(),
  //   }),
  // });

  return (
    <Fragment>
      <InvoiceFilters
        isOpen={filterDrawer}
        onClose={toggleFilterDrawer}
        totalResult={totalEntries}
        {...handlers}
        application_id={application_id}
        invoice_id={invoice_id}
        payment_id={payment_id}
      />
      <EuiPanel hasBorder>
        <EuiTitle size="s">
          <p>
            <h1>Invoice List</h1>
          </p>
        </EuiTitle>
        <EuiSpacer size="l" />
        <EuiFlexGroup style={{ margin: "0.8rem 0" }}>
          <EuiFlexItem>
            <div style={{ display: "flex", marginTop: "1rem" }}>
              <EuiText size="xs" color={"gray"}>
                Total Invoice: {totalEntries} |
              </EuiText>

              <EuiText
                onClick={() => {
                  console.log("refresh or clear");
                  resetFilter();
                }}
                size="xs"
                style={{
                  marginLeft: "10px",
                  color: "#005b8f",
                  backgroundColor: "transparent",
                  cursor: "pointer",
                }}
              >
                <EuiIcon type="refresh"></EuiIcon> Refresh
              </EuiText>
            </div>
          </EuiFlexItem>
          <EuiFlexItem
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "1rem",
            }}
          >
            <EuiButtonEmpty
              size="s"
              iconType="filter"
              onClick={toggleFilterDrawer}
            >
              Filters
            </EuiButtonEmpty>
            |
            <EuiSuperSelect
              onChange={handleStatus}
              valueOfSelected={status}
              options={[
                ...statusOptions,
                { value: "ALL", inputDisplay: "SELECT - status" },
              ]}
              style={{
                width: "13rem",
              }}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiInMemoryTable
          tableCaption="Demo of EuiInMemoryTable with selection"
          ref={invoiceTableRef}
          items={invoiceData.items || []}
          itemId="id"
          loading={loading}
          message={message}
          columns={invoiceColumn}
          sorting={true}
          // selection={}
          isSelectable={true}
        />
        <EuiSpacer size="l" />
        <EuiTablePagination
          aria-label="Table pagination example"
          pageCount={pageCount}
          activePage={activePage}
          onChangePage={goToPage}
          itemsPerPage={rowSize}
          onChangeItemsPerPage={changeItemsPerPage}
          itemsPerPageOptions={[5, 10]}
        />
      </EuiPanel>
    </Fragment>
  );
};

export default InvoiceList;
