import React from "react";
import { invoiceType } from "../utils";

const InvoiceTypeTag = ({ value: type, props }) => {
  return (
    <div
      style={{
        fontSize: "small",
        fontWeight: "bolder",
        height: "100%",
        width: "90%",
        paddingLeft: "5px",
        borderLeft: "2px " + invoiceType[type]?.color + " solid",
        backgroundColor: invoiceType[type]?.bg,
      }}
      {...props}
    >
      {type ? type : "N/A"}
    </div>
  );
};

export default InvoiceTypeTag;
