import { CM, CI, GA, GH, KE, MW, RW, SN, TZ, UG, ZM,GM, ET } from 'country-flag-icons/react/3x2'
// import Flags from 'country-flag-icons/react/3x2'

export const flagList ={
    cameroon: <CM width={ 20 } title="Cameroon" />,
    "cote d'ivoire": <CI width={ 20 } title="Cote d'ivoire" />,
    ethiopia: <ET width={ 20 } title="Ethiopia" />,
    gabon: <GA width={ 20 } title="Gabon" />,
    ghana: <GH width={ 20 } title="Ghana" />,
    kenya: <KE width={ 20 } title="Kenya" />,
    malawi: <MW width={ 20 } title="Malawi" />,
    rwanda: <RW width={ 20 } title="Rwanda" />,
    senegal: <SN width={ 20 } title="Senegal" />,
    tanzania: <TZ width={ 20 } title="Tanzania" />,
    uganda: <UG width={ 20 } title="Uganda" />,
    zambia: <ZM width={ 20 } title="Zambia" />,
    gambia: <GM width={ 20 } title="Gambia" />
}

// export const FlagList = ({value}) => {
//     console.log(value);
//     const Flag = Flags["US"]
//    return ( <Flag width={ 20 } title={value} /> )
// }
