import {
  EuiFlexGrid,
  EuiFlexItem,
  EuiImage,
  EuiInMemoryTable,
  EuiPanel,
  EuiSpacer,
  EuiTablePagination,
  EuiSkeletonText,
  EuiProgress,
  EuiText,
  EuiTitle,
  formatDate,
} from "@elastic/eui";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { fakeMerchantInvoiceData, message } from "../utils";
import { useParams } from "react-router-dom";
import {
  invoiceInstallmentsColumn,
  invoiceItemsColumn,
  invoicePaymentsColumn,
  merchantInvoiceColumn,
} from "../utils/columns";
import { profile } from "../../../merchants/overviewMerchants/topMerchants/columns";
import { getSingleInvoiceApi } from "../../../../services/invoice-service";
import { isAxiosError } from "axios";
// import { merchantInvoiceData } from "./utils";
import InvoiceTypeTag from "../components/invoiceTypeTag";
import InvoiceStatusbadge from "../components/invoiceStatusbadge";
import PaymentDetailsModal from "../components/paymentDetailsModal";
import InvoiceInstallment from "../components/invoiceInstallment";

const MerchantInvoiceList = () => {
  const { invoiceId, id } = useParams();
  const invoiceTableRef = useRef();
  const [merchantInvoiceData, setMerchantInvoiceData] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const goToPage = (pageNumber) => setActivePage(pageNumber);
  const [rowSize, setRowSize] = useState(10);
  const [totalEntries, setTotalEntries] = useState(0);

  const changeItemsPerPage = (pageSize) => {
    setPageCount(Math.ceil(totalEntries / pageSize));
    setRowSize(pageSize);
    setActivePage(0);
    localStorage.removeItem("merchantPaginationToken");
  };

  console.log({ id });

  const getInvoiceDetailsFn = async () => {
    setisLoading(true);
    try {
      const result = await getSingleInvoiceApi(String(invoiceId));
      setMerchantInvoiceData(result);
      console.log({ result });
    } catch (error) {
      if (isAxiosError(error)) {
        console.log({ errorMessage: error.response?.data?.message });
      }
    } finally {
      setisLoading(false);
    }
  };

  useEffect(() => {
    setisLoading(true);
    setisLoading(false);
    getInvoiceDetailsFn();
  }, []);

  const displayAmountAndCurrency = ({ amount, currency }) => {
    return (
      <div>
        {amount}{" "}
        <span
          style={{
            fontSize: "small",
            fontWeight: "bold",
          }}
        >
          {currency}
        </span>{" "}
      </div>
    );
  };

  const checkIfValueExist = (data) => {
    return isLoading ? (
      <EuiProgress
        size="m"
        color="subdued"
        style={{
          opacity: 0.1,
        }}
      />
    ) : data ? (
      data
    ) : (
      "N/A"
    );
  };

  const clientDetailsArray = [
    {
      title: "Name",
      value: checkIfValueExist(merchantInvoiceData?.client?.name),
    },
    {
      title: "Email",
      value: checkIfValueExist(merchantInvoiceData?.client?.email),
    },
    {
      title: "Phone number",
      value: checkIfValueExist(merchantInvoiceData?.client?.phone_number),
    },
  ];

  const companyDetailsArray = [
    {
      title: "Company Name",
      value: checkIfValueExist(merchantInvoiceData?.company?.name),
    },
    {
      title: "Company Email",
      value: checkIfValueExist(merchantInvoiceData?.company?.email),
    },
    {
      title: "Phone number",
      value: checkIfValueExist(merchantInvoiceData?.company?.phone_number),
    },
  ];

  const invoiceDetails = [
    { title: "ref", value: checkIfValueExist(merchantInvoiceData?.ref) },
    {
      title: "status",
      value: merchantInvoiceData?.status ? (
        <InvoiceStatusbadge status={merchantInvoiceData?.status} />
      ) : (
        "N/A"
      ),
    },
    {
      title: "type",
      value: merchantInvoiceData?.type ? (
        <InvoiceTypeTag value={merchantInvoiceData?.type} />
      ) : (
        "N/A"
      ),
    },
    {
      title: "total_amount",
      value: merchantInvoiceData?.total_amount
        ? displayAmountAndCurrency({
            amount: merchantInvoiceData?.total_amount,
            currency: merchantInvoiceData?.currency,
          })
        : "N/A",
    },
    {
      title: "amount_paid",
      value: merchantInvoiceData?.amount_paid
        ? displayAmountAndCurrency({
            amount: merchantInvoiceData?.amount_paid,
            currency: merchantInvoiceData?.currency,
          })
        : "N/A",
    },
    {
      title: "remaining_amount",
      value: merchantInvoiceData?.remaining_amount
        ? displayAmountAndCurrency({
            amount: merchantInvoiceData?.remaining_amount,
            currency: merchantInvoiceData?.currency,
          })
        : "N/A",
    },
    {
      title: "sub_total",
      value: merchantInvoiceData?.sub_total
        ? displayAmountAndCurrency({
            amount: merchantInvoiceData?.sub_total,
            currency: merchantInvoiceData?.currency,
          })
        : "N/A",
    },
    {
      title: "due_date",
      value: merchantInvoiceData?.due_date
        ? formatDate(merchantInvoiceData?.due_date)
        : "N/A",
    },
  ];

  const [paymentDetals, setPaymentDetals] = useState({
    data: null,
    open: false,
  });
  const [installmentsDetails, setInstallmentsDetails] = useState({
    data: null,
    open: false,
  });

  const closeInstallment = () =>
    setInstallmentsDetails((prev) => ({
      ...prev,
      open: false,
    }));

  const openInstallmentModal = (data) =>
    setInstallmentsDetails({
      data,
      open: true,
    });

  const openDetailsModal = (data) =>
    setPaymentDetals({
      data,
      open: true,
    });
  const closeDetailsModal = () =>
    setPaymentDetals((prev) => ({
      ...prev,
      open: false,
    }));

  return (
    <Fragment>
      <PaymentDetailsModal isOpen={paymentDetals} onClose={closeDetailsModal} />
      <InvoiceInstallment
        isOpen={installmentsDetails}
        onClose={closeInstallment}
      />

      <EuiTitle size="s">
        <p>
          <h1>
            Invoice Details -{" "}
            <span
              style={{
                color: "GrayText",
              }}
            >
              {merchantInvoiceData?.ref}
            </span>
          </h1>
        </p>
      </EuiTitle>
      <EuiSpacer size="l" />

      <EuiPanel paddingSize="m" hasShadow={false} hasBorder={false}>
        <EuiFlexGrid columns={3}>
          <EuiFlexItem>
            <EuiText
              style={{
                fontSize: "large",
                fontWeight: "bolder",
                textTransform: "capitalize",
              }}
            >
              client
            </EuiText>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: ".5rem",
              }}
            >
              {clientDetailsArray.map((items, i) => (
                <CleintAndCompanyText key={i} {...items} />
              ))}
            </div>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiImage
              size="m"
              hasShadow
              allowFullScreen
              caption={merchantInvoiceData?.company?.name}
              alt="" // Because this image is sufficiently described by its caption, there is no need to repeat it via alt text
              src={merchantInvoiceData?.company?.logo}
              style={{
                height: "7rem",
                width: "auto",
              }}
            />
          </EuiFlexItem>
          <EuiFlexItem
            style={{
              textAlign: "end",
            }}
          >
            <EuiText
              style={{
                fontSize: "large",
                fontWeight: "bolder",
                textTransform: "capitalize",
              }}
            >
              Company
            </EuiText>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: ".5rem",
              }}
            >
              {companyDetailsArray.map((items, i) => (
                <CleintAndCompanyText key={i} {...items} />
              ))}
            </div>
          </EuiFlexItem>
        </EuiFlexGrid>
      </EuiPanel>

      <EuiSpacer size="l" />

      <EuiPanel
        color="subdued"
        paddingSize="m"
        hasShadow={false}
        hasBorder={false}
      >
        <EuiFlexGrid columns={4}>
          {invoiceDetails?.map((items, i) => (
            <InvoiceDetailText key={i + "invoice-details"} {...items} />
          ))}
        </EuiFlexGrid>
      </EuiPanel>
      <EuiSpacer size="l" />
      <EuiPanel
        color="subdued"
        paddingSize="m"
        style={{ marginTop: "10px" }}
        hasShadow={false}
        // hasBorder={true}
      >
        <EuiTitle size="s">
          <p>
            <h1>Invoice Items</h1>
          </p>
        </EuiTitle>
        <EuiSpacer size="l" />
        <EuiInMemoryTable
          tableCaption="Demo of EuiInMemoryTable with selection"
          ref={invoiceTableRef}
          items={merchantInvoiceData.items || []}
          itemId="id"
          loading={isLoading}
          message={message}
          columns={invoiceItemsColumn}
          sorting={true}
          // selection={}
          isSelectable={true}
        />
        {/* <EuiInMemoryTable
          tableCaption="Demo of EuiInMemoryTable with selection"
          ref={invoiceTableRef}
          items={merchantInvoiceData || []}
          itemId="id"
          loading={isLoading}
          message={message}
          columns={merchantInvoiceColumn(invoiceId)}
          sorting={true}
          // selection={}
          isSelectable={true}
        /> */}
        <EuiSpacer size="l" />
        {/* <EuiTablePagination
          aria-label="Table pagination example"
          pageCount={pageCount}
          activePage={activePage}
          onChangePage={goToPage}
          itemsPerPage={rowSize}
          onChangeItemsPerPage={changeItemsPerPage}
          itemsPerPageOptions={[5, 10]}
        /> */}
      </EuiPanel>
      <EuiSpacer size="l" />
      <EuiPanel
        paddingSize="m"
        style={{ marginTop: "10px" }}
        hasShadow={false}
        // hasBorder={true}
        color="subdued"
      >
        <EuiTitle size="s">
          <p>
            <h1>Invoice Payments</h1>
          </p>
        </EuiTitle>
        <EuiSpacer size="l" />
        <EuiInMemoryTable
          tableCaption="Demo of EuiInMemoryTable with selection"
          ref={invoiceTableRef}
          items={merchantInvoiceData.payments || []}
          itemId="id"
          loading={isLoading}
          message={message}
          columns={invoicePaymentsColumn({ viewDetails: openDetailsModal })}
          sorting={true}
          // selection={}
          isSelectable={true}
        />

        <EuiSpacer size="l" />
        {/* <EuiTablePagination
          aria-label="Table pagination example"
          pageCount={pageCount}
          activePage={activePage}
          onChangePage={goToPage}
          itemsPerPage={rowSize}
          onChangeItemsPerPage={changeItemsPerPage}
          itemsPerPageOptions={[5, 10]}
        /> */}
      </EuiPanel>
      <EuiSpacer size="l" />
      <EuiPanel
        paddingSize="m"
        style={{ marginTop: "10px" }}
        hasShadow={false}
        // hasBorder={true}
        color="subdued"
      >
        <EuiTitle size="s">
          <p>
            <h1>Invoice installments</h1>
          </p>
        </EuiTitle>
        <EuiSpacer size="l" />
        <EuiInMemoryTable
          tableCaption="Demo of EuiInMemoryTable with selection"
          ref={invoiceTableRef}
          items={merchantInvoiceData.installments || []}
          itemId="id"
          loading={isLoading}
          message={message}
          columns={invoiceInstallmentsColumn({
            viewDetails: openInstallmentModal,
          })}
          sorting={true}
          // selection={}
          isSelectable={true}
        />

        <EuiSpacer size="l" />
        {/* <EuiTablePagination
          aria-label="Table pagination example"
          pageCount={pageCount}
          activePage={activePage}
          onChangePage={goToPage}
          itemsPerPage={rowSize}
          onChangeItemsPerPage={changeItemsPerPage}
          itemsPerPageOptions={[5, 10]}
        /> */}
      </EuiPanel>
    </Fragment>
  );
};

const CleintAndCompanyText = ({ title, value }) => {
  return (
    <EuiText
      style={{
        fontSize: "small",
        fontWeight: "bold",
        color: "GrayText",
        textTransform: "capitalize",
      }}
    >
      {title} :
      <span
        style={{
          fontSize: "medium",
          fontWeight: "normal",
          color: "black",
          textTransform: "none",
        }}
      >
        {" "}
        {value}
      </span>
    </EuiText>
  );
};

export const InvoiceDetailText = ({ title, value }) => {
  return (
    <EuiFlexItem>
      <EuiText
        style={{
          fontSize: "small",
          fontWeight: "bold",
          color: "GrayText",
          textTransform: "capitalize",
        }}
      >
        {title ? String(title).replaceAll("_", " ") : "undefined"}
      </EuiText>
      <EuiText>{value}</EuiText>
    </EuiFlexItem>
  );
};

export default MerchantInvoiceList;
