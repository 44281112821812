import { EuiIcon } from "@elastic/eui";
import { Link } from "react-router-dom";
import profile from "../../../../assets/images/avatar/avatar-blank.png";
import { flagList } from "../../infoList/flagList";


export {profile}
export const columns = [
    {
        field: 'id',
        name: 'ID',
        // render: (id, obj) => (
        //     <Link style={{ color:'black' }}><span style={{color:'lightgray'}}>#</span> { id }</Link>
        // ),
        truncateText: true
    },
    {
        field: 'picture',
        name: 'profile',
        render: (image, merchant) => (
            <>
                <img 
                    width={ 30 } 
                    style={{ borderRadius: '100px', marginRight: '5px' }} 
                    src={ image || profile } 
                    alt={ merchant.first_name }
                /> 
                <Link style={{ color:'black' }}>{ merchant.first_name }</Link>
            </>
        ),

    },
    {
        field: 'phone',
        name: 'Tel'
    },
    {
        field: 'email',
        name: 'Email',
        truncateText: true,
    },
    {
        field: 'country',
        name: 'Country',
        sortable: true,
        truncateText: true,
        render: (country) => (
            <><div style={{ marginRight: '8px' }}>{ flagList[country.toLowerCase()]}</div>  { country }</>
        )
    },
    {
        name: 'Actions',
        render: (obj) => (

        <Link to={`/merchants/merchantDetails/${obj.id}`}>
            Details <EuiIcon type='iInCircle' />
        </Link>
        ),
    },
];
