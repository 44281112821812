import axios from "../config/axios-config";

export const getAllInvoiceApi = async (query = {}) => {
  let { page, limit, status, application_id, payment_id, invoice_id } = query;

  let params = {
    ...(page && { page: page + 1 }),
    ...(limit && { limit }),
    ...(status && { status }),
    ...(application_id && { application_id }),
    ...(payment_id && { payment_id }),
    ...(invoice_id && { invoice_id }),
  };
  const result = await axios.get("invoice", { params });
  return result?.data;
};

export const getSingleInvoiceApi = async (id) => {
  let url = "invoice/" + id;
  const result = await axios.get(url);
  return result?.data;
};
