import {
  EuiBadge,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiInMemoryTable,
  EuiPanel,
  EuiSpacer,
  EuiTablePagination,
  formatDate,
} from "@elastic/eui";
import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
  fakeDetailsData,
  fakeTotal,
  invoiceheaderInfo,
  orderInformation,
  userDetails,
} from "./utils";
import { Divider } from "../../partnerPortal/billing/utils";
import { EuiTitle } from "@elastic/eui";
import { message } from "../List/utils";

const InvoiceDetails = () => {
  const { id } = useParams();

  const tableRef = useRef();

  const layout = (data) => {
    return (
      <span
        style={{ fontWeight: "600", fontSize: "17px", marginBottom: "1.5rem" }}
      >
        {data}
      </span>
    );
  };

  const divider = (
    <div style={{ margin: "2rem 0" }}>
      <Divider />
    </div>
  );

  const invoices = [
    {
      invoiceNumber: "INV001",
      date: "2024-09-10",
      client: "ABC Corp",
      amount: 500.0,
      status: "Paid",
    },
    {
      invoiceNumber: "INV002",
      date: "2024-09-11",
      client: "XYZ Ltd",
      amount: 750.0,
      status: "Pending",
    },
    {
      invoiceNumber: "INV003",
      date: "2024-09-12",
      client: "Tech Solutions",
      amount: 1200.0,
      status: "Overdue",
    },
    {
      invoiceNumber: "INV004",
      date: "2024-09-13",
      client: "Design Studio",
      amount: 300.0,
      status: "Paid",
    },
  ];

  return (
    <div>
      {/* InvoiceDetails for {id} */}
      <EuiPanel hasShadow={false}>
        <div style={{ fontSize: "1.8rem", fontWeight: "600" }}>
          invoice : BB - Gumroad - 0074
        </div>
        <EuiFlexGrid
          columns={3}
          style={{
            margin: "1rem 0",
          }}
        >
          <EuiFlexItem>
            <div style={{ marginBottom: ".3rem", marginTop: ".5rem" }}>
              invoice number - <span>0074</span>
            </div>
          </EuiFlexItem>
          <EuiFlexItem>
            <div style={{ marginBottom: ".3rem" }}>
              issue date - <span>31/66/2021</span>
            </div>
          </EuiFlexItem>
          <EuiFlexItem>
            <div style={{ marginBottom: ".3rem" }}>
              Merchant - <span>Merchant 1</span>
            </div>
          </EuiFlexItem>
          <EuiFlexItem>
            <div style={{ marginBottom: ".3rem" }}>
              location - <span>Bonamoussadi, sable</span>
            </div>
          </EuiFlexItem>
          <EuiFlexItem>
            <div style={{ marginBottom: ".3rem" }}>
              email - <span>Mercahnt@email.com</span>
            </div>
          </EuiFlexItem>
        </EuiFlexGrid>
        {divider}

        <InfoComponent array={userDetails} title={"Client information"} />
        {divider}
        <InfoComponent array={orderInformation} title="Order information" />
      </EuiPanel>

      <EuiPanel
        paddingSize="m"
        style={{ marginTop: "10px" }}
        hasShadow={false}
        hasBorder={true}
      >
        <EuiTitle size="s">
          <p>
            <h1>Invoice </h1>
          </p>
        </EuiTitle>
        <EuiSpacer size="l" />
        <EuiInMemoryTable
          tableCaption="Demo of EuiInMemoryTable with selection"
          ref={tableRef}
          items={invoices || []}
          itemId="id"
          loading={false}
          message={message}
          columns={invoiceColumn()}
          sorting={true}
          // selection={}
          isSelectable={true}
        />
        <EuiSpacer size="l" />
        <EuiTablePagination
          aria-label="Table pagination example"
          pageCount={10}
          activePage={1}
          onChangePage={() => {}}
          itemsPerPage={10}
          onChangeItemsPerPage={() => {}}
          itemsPerPageOptions={[5, 10]}
        />
      </EuiPanel>
    </div>
  );
};

const InfoComponent = ({ title, array }) => {
  return (
    <>
      <div>
        <h2
          style={{
            fontSize: "large",
            fontWeight: "600",
          }}
        >
          {title}
        </h2>
      </div>

      <EuiFlexGrid
        columns={3}
        style={{
          margin: "1rem 0",
        }}
      >
        {array?.map((items, i) => (
          <EuiFlexItem
            key={i + "userDetails"}
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "1rem",
              width: "100%",
            }}
          >
            {items?.title ? (
              <h3>
                {items?.title} {" : "}
              </h3>
            ) : null}
            <p> {items?.value}</p>
          </EuiFlexItem>
        ))}
      </EuiFlexGrid>
    </>
  );
};

const displayValue = (value) => (value ? value : "N/A");

const invoiceColumn = () => {
  return [
    {
      field: "invoiceNumber",
      name: "Invoice Number",
      render: (obj) => displayValue(obj),
    },
    {
      field: "date",
      name: "Date",
      render: (obj) => displayValue(formatDate(obj)),
    },
    {
      field: "client",
      name: "Client",
      render: (obj) => displayValue(obj),
    },
    {
      field: "amount",
      name: "Amount",
      render: (obj) => displayValue(obj),
    },
    {
      field: "status",
      name: "Status",
      render: (obj) => displayValue(obj),
    },
  ];
};

export default InvoiceDetails;
