import { EuiBadge, EuiButtonEmpty, EuiIcon, formatDate } from "@elastic/eui";
import { profile } from "../../../merchants/overviewMerchants/topMerchants/columns";
import { Link } from "react-router-dom";
import { invoiceStatus, invoiceType } from ".";
import InvoiceTypeTag from "../components/invoiceTypeTag";
import InvoiceStatusbadge from "../components/invoiceStatusbadge";

const date = new Date();

const checkData = (data) => (data ? data : "N/A");

export const invoiceColumn = [
  {
    field: "ref",
    name: "Reference",
    render: (obj) => <div>{obj}</div>,
    truncateText: true,
  },

  {
    field: "type",
    name: "Type",
    render: (type, obj) => <InvoiceTypeTag value={type} />,
  },
  {
    field: "status",
    name: "Status",
    truncateText: true,
    render: (status, obj) => <InvoiceStatusbadge status={status} />,
  },
  {
    field: "due_date",
    name: "Due date",
    truncateText: true,
    render: (due_date, obj) => <div> {formatDate(due_date)}</div>,
  },

  {
    field: "total_amount",
    name: "Total amount",
    truncateText: true,
    render: (total_amount, obj) => (
      <div>
        {" "}
        {total_amount}{" "}
        <span
          style={{
            fontSize: "small",
            fontWeight: "bold",
          }}
        >
          {obj.currency}
        </span>
      </div>
    ),
  },
  {
    field: "remaining_amount",
    name: "Remaining amount",
    truncateText: true,
    render: (remaining_amount, obj) => (
      <div>
        {" "}
        {remaining_amount}{" "}
        <span
          style={{
            fontSize: "small",
            fontWeight: "bold",
          }}
        >
          {obj.currency}
        </span>
      </div>
    ),
  },
  {
    name: "Action",
    render: (obj) => (
      <>
        <Link to={`/invoice/list/${obj?.uuid}`}>
          Details <EuiIcon type="document" />
        </Link>
      </>
    ),
  },
];

export const invoiceItemsColumn = [
  {
    field: "ref",
    name: " Reference",
    render: (ref, obj) => checkData(ref),
    truncateText: true,
  },
  {
    field: "name",
    name: " Name",
    render: (name, obj) => checkData(name),
    truncateText: true,
  },
  {
    field: "amount",
    name: " Amount",
    render: (amount, obj) => checkData(amount),
    truncateText: true,
  },
  {
    field: "quantity",
    name: " Quantity",
    render: (quantity, obj) => checkData(quantity),
    truncateText: true,
  },
];

export const invoiceInstallmentsColumn = ({ viewDetails }) => {
  return [
    {
      field: "ref",
      name: " Reference",
      render: (ref, obj) => checkData(ref),
      truncateText: true,
    },
    {
      field: "title",
      name: " Title",
      render: (title, obj) => checkData(title),
      truncateText: true,
    },

    {
      field: "amount",
      name: " Amount",
      render: (amount, obj) => (
        <div>
          {checkData(amount)}{" "}
          <span
            style={{
              fontSize: "small",
              fontWeight: "bold",
            }}
          >
            {obj.currency}
          </span>
        </div>
      ),
      truncateText: true,
    },
    {
      field: "status",
      name: " Status",
      render: (status, obj) => checkData(status),
      truncateText: true,
    },
    {
      field: "due_date",
      name: " Due date",
      render: (due_date, obj) => (due_date ? formatDate(due_date) : "/"),
      truncateText: true,
    },
    {
      name: " Actions",
      render: (obj) => (
        <EuiButtonEmpty onClick={() => viewDetails(obj)} iconType={"document"}>
          Details
        </EuiButtonEmpty>
      ),
      truncateText: true,
    },
  ];
};

export const invoicePaymentsColumn = ({ viewDetails }) => {
  return [
    {
      field: "ref",
      name: " Reference",
      render: (ref, obj) => checkData(ref),
      truncateText: true,
    },
    // {
    //   field: "transaction_id",
    //   name: " Transaction Id",
    //   render: (transaction_id, obj) => checkData(transaction_id),
    //   truncateText: true,
    // },
    {
      field: "amount",
      name: " Amount",
      render: (amount, obj) => (
        <div>
          {checkData(amount)}{" "}
          <span
            style={{
              fontSize: "small",
              fontWeight: "bold",
            }}
          >
            {obj.currency}
          </span>
        </div>
      ),
      truncateText: true,
    },
    // {
    //   field: "provider_message",
    //   name: " Provider Message",
    //   render: (provider_message, obj) => (
    //     <div>{checkData(provider_message)} </div>
    //   ),
    //   truncateText: true,
    // },
    {
      field: "status",
      name: " Status",
      render: (status, obj) => checkData(status),
      truncateText: true,
    },
    {
      field: "created_at",
      name: " Created at",
      render: (created_at, obj) => formatDate(created_at),
      truncateText: true,
    },
    {
      name: " Actions",
      render: (obj) => (
        <EuiButtonEmpty onClick={() => viewDetails(obj)} iconType={"document"}>
          Details
        </EuiButtonEmpty>
      ),
      truncateText: true,
    },
  ];
};

export const merchantInvoiceColumn = (invoiceId) => [
  {
    field: "id",
    name: " Id",
    render: (id, obj) => <div>Invoice # {id}</div>,
    truncateText: true,
  },
  {
    field: "client_name",
    name: "Cleint Name",
    render: (obj) => (
      <>
        <div style={{ color: "black" }}>{obj}</div>
      </>
    ),
  },
  {
    field: "product_name",
    name: "Product Name",
    render: (obj) => (
      <>
        <div style={{ color: "black" }}>{obj}</div>
      </>
    ),
  },
  {
    field: "quantity",
    name: "Quantity",
    render: (obj) => (
      <>
        <div style={{ color: "black" }}>{obj}</div>
      </>
    ),
  },
  {
    field: "total_price",
    name: "Total price",
    render: (obj) => (
      <>
        <div style={{ color: "black" }}>{obj}</div>
      </>
    ),
  },
  {
    field: "date",
    name: "Date",
    truncateText: true,
    render: (date, obj) => <div> {date}</div>,
  },
  {
    name: "Action",
    render: (obj) => (
      <>
        <Link to={`/invoice/list/${invoiceId}/${obj?.id}`}>
          Details <EuiIcon type="document" />
        </Link>
      </>
    ),
  },
];
