import {
  EuiButton,
  EuiFlexGrid,
  EuiFlexItem,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  formatDate,
} from "@elastic/eui";
import React from "react";
import { InvoiceDetailText } from "../merchantInvoiceList";

const PaymentDetailsModal = ({ onClose, isOpen }) => {
  const checkData = (data) => (data ? data : "N/A");
  const checkDateData = (data) => (data ? formatDate(data) : "N/A");
  const detailsModal = [
    {
      title: "Reference",
      value: checkData(isOpen?.data?.ref),
    },
    {
      title: "transaction_id",
      value: checkData(isOpen?.data?.transaction_id),
    },
    {
      title: "amount",
      value: (
        <div>
          {checkData(isOpen?.data?.amount)}{" "}
          <span>{isOpen?.data?.currency ? isOpen?.data?.currency : null}</span>
        </div>
      ),
    },
    {
      title: "provider_message",
      value: checkData(isOpen?.data?.provider_message),
    },
    {
      title: "status",
      value: checkData(isOpen?.data?.status),
    },
    {
      title: "created_at",
      value: checkDateData(isOpen?.data?.created_at),
    },
    {
      title: "lastChangedDateTime",
      value: checkDateData(isOpen?.data?.lastChangedDateTime),
    },
  ];

  let modal;

  if (isOpen.open) {
    modal = (
      <EuiModal aria-labelledby={"modalTitleId"} onClose={onClose}>
        <EuiModalHeader>
          <EuiModalHeaderTitle id={"modalTitleId"}>
            Payment Details : {isOpen?.data?.ref}
          </EuiModalHeaderTitle>
        </EuiModalHeader>

        <EuiModalBody>
          <EuiFlexGrid columns={2}>
            {detailsModal?.map((items, i) => (
              <EuiFlexItem key={i + "detailsModal"}>
                <InvoiceDetailText {...items} />
              </EuiFlexItem>
            ))}
          </EuiFlexGrid>
        </EuiModalBody>
        <EuiModalFooter>
          <EuiButton onClick={onClose} size="s" fill color="text">
            close
          </EuiButton>
        </EuiModalFooter>
      </EuiModal>
    );
  }

  return <>{modal}</>;
};

export default PaymentDetailsModal;
