import { EuiEmptyPrompt } from "@elastic/eui";

export const fakeInvoiceData = [
  {
    invoiceId: 1,
    first_name: "John",
    image: "john_invoice.jpg",
    date: "2024-03-04",
  },
  {
    invoiceId: 2,
    first_name: "Jane",
    image: "jane_invoice.png",
    date: "2024-03-05",
  },
  {
    invoiceId: 3,
    first_name: "Bob",
    image: "bob_invoice.jpeg",
    date: "2024-03-06",
  },
];

export const fakeMerchantInvoiceData = [
  {
    id: 1,
    client_name: "John Doe",
    product_name: "Widget",
    quantity: 5,
    total_price: 50.0,
    date: "2024-03-06",
  },
  {
    id: 2,
    client_name: "Jane Smith",
    product_name: "Gadget",
    quantity: 3,
    total_price: 30.5,
    date: "2024-03-06",
  },
  {
    id: 3,
    client_name: "Bob Johnson",
    product_name: "Tool",
    quantity: 2,
    total_price: 20.0,
    date: "2024-03-07",
  },
  {
    id: 4,
    client_name: "Alice Brown",
    product_name: "Accessory",
    quantity: 1,
    total_price: 15.75,
    date: "2024-03-07",
  },
];

export const message = (
  <EuiEmptyPrompt
    title={<h3>No data found</h3>}
    titleSize="xs"
    body="Looks like you don&rsquo;t have any data."
  />
);

export const invoiceStatus = {
  PAID: {
    name: "Paid",
    color: "#0077b6",
  },
  UNPAID: {
    name: "Unpaid",
    color: "#d00000",
  },
  PARTIALLY_PAID: {
    name: "Partially paid",
    color: "#2d6a4f",
  },
};

export const invoiceType = {
  INSTALLMENT: {
    color: "#774936",
    bg: "#ede0d4",
    name: "INSTALLMENT",
  },
  NORMAL: {
    color: "#58148e",
    bg: "#f7ebfd",
    name: "NORMAL",
  },
};
