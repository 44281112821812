import { EuiBadge } from "@elastic/eui";

export const userDetails = [
    {
      value: (
        <span style={{ fontWeight: "700", fontSize: "19px", color:"black" }}>John Doe</span>
      ),
    },
    {
      title: "email",
      value:" Useremail@gmail.com"
    },
    {
        title: "Username",
        value:"@userName"
      },
      {
        title:"contact",
        value:"+237675675675"
      }
  ];

  export const orderInformation = [
    {
      title: "date",
      value: "novenber 14",
    },
    {
      title: "status",
      value: <EuiBadge color="warning">pending</EuiBadge>,
    },
    {
      title: "id",
      value: "# 923524",
    },
  ];

  export const invoiceheaderInfo = [
    
    {
      title:<div >Total Due</div>,
      value:<div>$950.00</div>
    }
  ]

  // function sortArrays()

  export const fakeDetailsData = {
    description: [
    "procuct 1",
    "procuct 1",
     "procuct 1",
    "procuct 1",

  ],
  quantity:[
    5,6,7,8
  ],
  amount:[
    500,600,700,780
  ],
  total:[
    5*500,
    6*600,
    7*700,
    8*780
  ]
}


export const fakeTotal = [
  {
    title:"sub total",
    value:"4725"
  },
  {
    title:"Fees / taxes (10%)",
    value:"50"
  },
 {
  title:"title",
  value:"45"
 }
]