import { EuiBadge } from "@elastic/eui";
import React from "react";
import { invoiceStatus } from "../utils";

const InvoiceStatusbadge = ({ status }) => {
  return (
    <EuiBadge color={invoiceStatus[status]?.color}>
      {" "}
      {invoiceStatus[status]?.name}
    </EuiBadge>
  );
};

export default InvoiceStatusbadge;
