import { EuiCollapsibleNavGroup, EuiPinnableListGroup } from '@elastic/eui';
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

const Sidenav = () => {
    const location = useLocation();
	const navigate = useNavigate();


    const LinksInvoices = [
		{
			label: "Invoices",
			isActive: location.pathname.indexOf("list") > 0,
			pinnable: false,
			className: "pnter-select",
			onClick: () => navigate("list"),
		},
		
	];

    return (
		<>
			<EuiCollapsibleNavGroup
				title="Invoice"
				iconType="codeApp"
				isCollapsible={false}
				initialIsOpen={true}
				style={{ position: "fixed", width: "13%", minWidth: "150px", top: 100 }}
			>
				<EuiPinnableListGroup
					listItems={LinksInvoices}
					onPinClick={() => { }}
					maxWidth={'90%'}
					color="subdued"
					gutterSize="none"
					size="s"
				/>
			</EuiCollapsibleNavGroup>
		</>
	);
}

export default Sidenav