import {
  EuiFieldText,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutHeader,
  EuiPanel,
  EuiText,
  EuiTitle,
} from "@elastic/eui";
import React from "react";

const InvoiceFilters = ({
  isOpen,
  onClose,
  totalResult,
  handleApplicationId,
  application_id,
  handlePaymentID,
  payment_id,
  handleInvoiceId,
  invoice_id,
}) => {
  const divider = (
    <div
      style={{
        height: "1px",
        width: "100%",
        backgroundColor: "lightgray",
        margin: "2rem 0",
      }}
    />
  );
  let flyout;

  if (isOpen) {
    flyout = (
      <EuiFlyout
        ownFocus
        onClose={onClose}
        aria-labelledby={"simpleFlyoutTitleId"}
        size="s"
      >
        <EuiFlyoutHeader hasBorder>
          <EuiTitle size="m">
            <h2 id={"simpleFlyoutTitleId"}>Invoice Filters</h2>
          </EuiTitle>
        </EuiFlyoutHeader>
        <EuiFlyoutBody>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <CustomTextFieldFilter
              label={"application_id"}
              onChange={handleApplicationId}
              value={application_id}
            />
            <CustomTextFieldFilter
              label={"payment_id"}
              onChange={handlePaymentID}
              value={payment_id}
            />
            <CustomTextFieldFilter
              label={"invoice_id"}
              onChange={handleInvoiceId}
              value={invoice_id}
            />
          </div>

          <>
            {divider}

            <EuiPanel
              hasShadow={false}
              color="primary"
              style={{
                display: "flex",
                alignItems: "center",
                gap: ".8rem",
                border: "1px lightGray solid",
                padding: "1rem",
                borderRadius: "8px",
                backgroundColor: "#353535",
                color: "white",
              }}
            >
              Total results :
              <span
                style={{
                  fontWeight: "bolder",
                }}
              >
                {totalResult}
              </span>
            </EuiPanel>
          </>
        </EuiFlyoutBody>
      </EuiFlyout>
    );
  }
  return <div>{flyout}</div>;
};

const CustomTextFieldFilter = ({ label, value, onChange }) => {
  return (
    <div>
      <EuiText
        size="xs"
        style={{
          marginBottom: "5px",
          fontWeight: "bold",
        }}
      >
        {label.toString().replaceAll("_", " ")}
      </EuiText>
      <EuiFieldText
        value={value}
        onChange={onChange}
        placeholder={"Filter by " + label.toString().replaceAll("_", " ")}
      />
    </div>
  );
};
export default InvoiceFilters;
