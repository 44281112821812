import { EuiIcon, EuiLink, EuiPageTemplate } from '@elastic/eui';
import React, { useState } from 'react'
import { Link, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import Sidenav from './sidenav';

const Invoices = () => {
    const [showBottomBar, setshowBottomBar] = useState(false);
	const location = useLocation();
	const navigate = useNavigate();
	const { account_id, profile_id } = useParams();

	const titleRoutes = {
		list: "Invoice",
	};
    return (
		<>
			<EuiPageTemplate
				style={{ paddingTop: "10vh" }}
				template="default"
				pageSideBar={<Sidenav />}
				paddingSize="m"
				minHeight={"100vh"}
				pageSideBarProps={{
					position: "sticky",
				}}
				bottomBar={showBottomBar && "Bottom bar"}
				pageHeader={{
					rightSideItems: [],
					pageTitle: (
						<>
							<div style={{ fontSize: "13px" }}>
								<Link onClick={() => navigate(-1)}>
									<EuiIcon type={"arrowLeft"}></EuiIcon> Go Back
								</Link>
							</div>

							{titleRoutes[location.pathname.split("/invoice/")[1]]}
						</>
					),
				}}
			>
				<Outlet></Outlet>
			</EuiPageTemplate>
		</>
	);
}

export default Invoices