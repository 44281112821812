import React, { useState } from "react";
import { invoiceStatus } from "../utils";

const useInvoiceListFilters = () => {
  const [status, setStatus] = useState("ALL");
  const [application_id, setAppID] = useState("");
  const [payment_id, setPaymentId] = useState("");
  const [invoice_id, setInvoiceId] = useState("");

  const resetFilter = () => {
    setStatus("ALL");
    setAppID("");
    setPaymentId("");
    setInvoiceId("");
  };

  const handleStatus = (value) => setStatus(value);

  const statusOptions = Object.keys(invoiceStatus).map((items) => ({
    value: items,
    inputDisplay: (
      <div
        style={{
          fontSize: "small",
          fontWeight: "bolder",
          display: "flex",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        <div
          style={{
            height: "6px",
            width: "6px",
            borderRadius: "100%",
            backgroundColor: invoiceStatus[items]?.color,
          }}
        />{" "}
        {items.replaceAll("_", " ")}
      </div>
    ),
  }));

  const handlers = {
    handleApplicationId: (e) => setAppID(e.target.value),
    handlePaymentID: (e) => setPaymentId(e.target.value),
    handleInvoiceId: (e) => setInvoiceId(e.target.value),
  };

  return {
    status,
    application_id,
    payment_id,
    invoice_id,
    resetFilter,
    statusOptions,
    handleStatus,
    handlers,
  };
};

export default useInvoiceListFilters;
